<template>
  <div>
    <back-with-title
      title="Employees"
      route-name="calculator"
      :params="{ company: $route.query.company }"
    />

    <b-overlay :show="isLoading">
      <b-table
        id="employees-table"
        :items="employees"
        :fields="fields"
        striped
        responsive
        selectable
        show-empty
        @row-clicked="row => onRowClicked(row)"
      >
        <template #head()="data">
          <span class="text-capitalize">{{ data.label }}</span>
        </template>
        <!-- Name -->
        <template #cell(name)="data">
          {{ data.item.name }}
          <feather-icon :icon="data.detailsShowing ? 'ChevronUpIcon' : 'ChevronDownIcon'" />
        </template>
        <!-- Gender -->
        <template #cell(gender)="data">
          <span class="text-capitalize">{{ data.item.gender.toLowerCase() }}</span>
        </template>
        <!-- Region -->
        <template #cell(region)="data">
          <span>{{ `${data.item.region.country} - ${data.item.region.city}` }}</span>
        </template>
        <!-- Salary -->
        <template #cell(salary)="data">
          <span>{{ formatAmount(data.item.salary) }}</span>
        </template>

        <!-- Actions -->
        <template #cell(actions)="data">
          <b-button
            v-b-tooltip.hover="'View Report'"
            variant="light"
            @click="showSingleEmployeeModal(data.item)"
          >
            <feather-icon icon="EyeIcon" />
          </b-button>
        </template>

        <!-- Employee Details -->
        <template #row-details="row">
          <!-- Dependents -->
          <b-button
            v-b-toggle.employee-dependents
            variant="link"
            class="text-left"
            block
          >
            Dependents ({{ row.item.dependents.data.length }})
            <feather-icon :icon="dependentsShown ? 'ChevronUpIcon' : 'ChevronDownIcon'" />
          </b-button>

          <b-collapse
            id="employee-dependents"
            accordion="employee"
            @show="() => { dependentsShown = true }"
            @hide="() => { dependentsShown = false }"
          >
            <b-table
              :items="row.item.dependents.data"
              :fields="dependentFields"
              class="ml-2"
              striped
              responsive
              show-empty
            >
              <template #head()="data">
                <span class="text-capitalize">{{ data.label }}</span>
              </template>
              <!-- Gender -->
              <template #cell(gender)="data">
                <span class="text-capitalize">{{ data.item.gender.toLowerCase() }}</span>
              </template>
              <!-- Gender -->
              <template #cell(relationship)="data">
                <span class="text-capitalize">{{ data.item.relationship.toLowerCase() }}</span>
              </template>
            </b-table>
          </b-collapse>

          <!-- Coverage Plans -->
          <b-button
            v-b-toggle.employee-coverages
            variant="link"
            class="text-left"
            block
          >
            Coverage Plans
            <feather-icon :icon="coverageShown ? 'ChevronUpIcon' : 'ChevronDownIcon'" />
          </b-button>

          <b-collapse
            id="employee-coverages"
            accordion="employee"
            @show="() => { getCoverageTypes(row.item.region.id); coverageShown = true; }"
            @hide="() => { coverageShown = false }"
          >
            <b-overlay :show="isProcessing">
              <b-table
                :items="coverageTypes"
                :fields="coverageFields"
                class="ml-2"
                striped
                responsive
                show-empty
              >
                <template #head()="data">
                  <span class="text-capitalize">{{ data.label }}</span>
                </template>

                <template #cell(coverageQuality)>
                  {{ row.item.coverageQuality }}
                </template>

                <template #cell(coverageAmount)="data">
                  {{ formatAmount(getCoverage(data.item.coverages.data, row.item.age, row.item.coverageQuality)) }}
                </template>
              </b-table>
            </b-overlay>
          </b-collapse>
        </template>
      </b-table>

      <!-- Pagination -->
      <b-pagination
        v-if="total > perPage"
        v-model="currentPage"
        :per-page="perPage"
        :total-rows="total"
        class="float-right py-2"
        aria-controls="employees-table"
        @input="getEmployees"
      />
    </b-overlay>

    <!-- Single Employee Report Modal -->
    <single-employee-report
      v-if="selectedEmployee"
      :employee="selectedEmployee"
    />
  </div>
</template>

<script>
import {
  BTable, BOverlay, BCollapse, BButton, VBToggle, BPagination, VBTooltip,
} from 'bootstrap-vue'
import BackWithTitle from '@/views/common/components/BackWithTitle.vue'
import useApollo from '@/plugins/graphql/useApollo'
import SingleEmployeeReport from './SingleEmployeeReport.vue'

export default {
  components: {
    SingleEmployeeReport,
    BackWithTitle,
    BTable,
    BOverlay,
    BCollapse,
    BButton,
    BPagination,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      employees: [],
      coverageTypes: [],
      coverages: [],
      fields: ['name', 'age', 'gender', 'region', 'salary'],
      dependentFields: ['name', 'age', 'gender', 'relationship'],
      coverageFields: ['name', 'coverageQuality', 'coverageAmount'],
      isLoading: false,
      isProcessing: false,
      dependentsShown: false,
      coverageShown: false,
      currentRegion: null,
      total: 0,
      perPage: 10,
      currentPage: 1,
      selectedEmployee: null,
    }
  },
  mounted() {
    this.getEmployees()
  },
  methods: {
    onRowClicked(row) {
      this.coverageShown = false
      this.dependentsShown = false
      this.showDetails(row)
    },
    showDetails(row) {
      // eslint-disable-next-line no-underscore-dangle
      if (row._showDetails) {
        this.$set(row, '_showDetails', false)
      } else {
        this.employees.forEach(item => {
          this.$set(item, '_showDetails', false)
        })

        this.$nextTick(() => {
          this.$set(row, '_showDetails', true)
        })
      }
    },
    getEmployees() {
      this.isLoading = true
      useApollo.calculator.getAllEmployees({
        companyId: this.$route.query.company,
        page: this.currentPage,
        first: this.perPage,
      }).then(response => {
        const data = response.data.me.tempCompanies?.[0]?.employees
        this.employees = data?.data
        this.total = data?.total
      }).finally(() => { this.isLoading = false })
    },
    getCoverageTypes(regionId) {
      if (this.currentRegion !== regionId) {
        this.currentRegion = regionId
        this.isProcessing = true
        useApollo.calculator.getCoveragesByCategoryInRegion({
          regionId,
        }).then(response => {
          this.coverageTypes = response.data.regions.data[0]?.coverageCategories?.data
        }).finally(() => { this.isProcessing = false })
      }
    },
    getCoverage(coverages, age, quality) {
      return coverages.find(coverage => coverage.minAge <= age && coverage.maxAge >= age)[quality.toLowerCase()]
    },
    showSingleEmployeeModal(employee) {
      this.selectedEmployee = employee
      this.$nextTick(() => {
        this.$bvModal.show('single-employee-report-modal')
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.dark-layout {
  .btn-light{
    background-color: #343D55;
    color: #f1f1f1;
    border: 1px solid #343D55;
  }
}
</style>
