<template>
  <b-modal
    id="single-employee-report-modal"
    :title="`${employee.name}'s Report`"
    size="lg"
    ok-title="Close"
    ok-only
  >
    <div id="single-employee-report">
      <b-card>
        <div class="d-flex justify-content-between">
          <h5>Employee Details</h5>
          <b-button
            variant="primary"
            @click="exportPdf"
          >
            <feather-icon icon="FileIcon" />
            Export PDF
          </b-button>
        </div>
        <p>Name: {{ employee.name }}</p>
        <p>Age: {{ employee.age }}</p>
        <p>Gender: <span class="text-capitalize">{{ employee.gender.toLowerCase() }}</span></p>
        <p>Region: {{ employee.region.country }} - {{ employee.region.city }}</p>

        <h5 class="mt-4 mb-2">
          Coverage Details
        </h5>
        <p>Quality of Coverage: <span class="text-capitalize">{{ employee.coverageQuality.toLowerCase() }}</span></p>
        <div
          v-for="coverageType in coverageTypes"
          :key="coverageType.id"
        >
          <p>{{ coverageType.name }}: {{ formatAmount(getCoverageAmount(coverageType.id)) }}</p>
        </div>
      </b-card>

      <div v-if="employee.dependents.data.length">
        <b-card>
          <h5 class="mb-2">
            Employee's Dependents
          </h5>
          <b-row>
            <b-col
              v-for="dependent, index in employee.dependents.data"
              :key="dependent.id"
              md="6"
            >
              <h6>Dependent {{ index + 1 }}</h6>
              <p>Name: {{ dependent.name }}</p>
              <p>Age: {{ dependent.age }}</p>
              <p>Gender: <span class="text-capitalize">{{ dependent.gender.toLowerCase() }}</span></p>
              <p>Relation: <span class="text-capitalize">{{ dependent.relationship.toLowerCase() }}</span></p>

              <h5 class="mt-4 mb-2">
                Coverage Details
              </h5>
              <p>Quality of Coverage: <span class="text-capitalize">{{ dependent.coverageQuality.toLowerCase() }}</span></p>
              <div
                v-for="coverageType in coverageTypes"
                :key="coverageType.id"
              >
                <p>{{ coverageType.name }}: {{ formatAmount(getCoverageAmount(coverageType.id, dependent.id)) }}</p>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </div>

  </b-modal>
</template>

<script>
import {
  BModal, BCard, BRow, BCol, BButton,
} from 'bootstrap-vue'
import useApollo from '@/plugins/graphql/useApollo'
import html2pdf from 'html2pdf.js'

export default {
  components: {
    BModal,
    BCard,
    BRow,
    BCol,
    BButton,
  },
  props: {
    employee: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      coverageTypes: [],
    }
  },
  mounted() {
    this.getCoverageTypes()
  },
  methods: {
    getCoverageTypes() {
      this.isProcessing = true
      useApollo.calculator.getCoveragesByCategoryInRegion({
        regionId: this.employee.region.id,
      }).then(response => {
        this.coverageTypes = response.data.regions.data[0]?.coverageCategories.data
      }).finally(() => { this.isProcessing = false })
    },
    getCoverageAmount(typeId, depId = null) {
      let person = this.employee
      if (depId) {
        person = this.employee.dependents.data.find(d => d.id === depId)
      }
      return this.coverageTypes.find(type => type.id === typeId).coverages.data.find(coverage => coverage.minAge <= person.age && coverage.maxAge >= person.age)[person.coverageQuality.toLowerCase()]
    },
    exportPdf() {
      const element = document.getElementById('single-employee-report')
      const opt = {
        margin: 0,
        filename: `${this.employee.name}-coverage-report.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: {
          scale: 2,
          width: element.offsetWidth * 0.796,
          pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
        },
        jsPDF: { format: 'a4', orientation: 'portrait' },
      }

      // New Promise-based usage:
      html2pdf().set(opt).from(element).save()
    },
  },
}
</script>
